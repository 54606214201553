<template>
 
      <div class="outer">
          <div class=" vertFlex fullheight right10 rightline">
            <label for="" class="titlelabel">Groups</label>
            <div class="scrolldiv" v-if="groupList.length>0">
                <div v-for="result in groupList" :key="result.id" :value="result.id" >
                    <list-item :topLeft="displayName(result)"  bottomLeft=""    @click="selectGroup(result)"
                        :selected="result.id==selectedGroupID" :inactive="!result.isActive">
                    </list-item>  
                </div>
            </div>
            <div class="horizFlex">
                <addgroup :showButton="isTrue" @saved="getGroups" buttonclass="button buttonprimary narrowButton"/>
                <renamegroup :showButton="isTrue" @saved="getGroups" :productGroup="selectedGroup" buttonclass="button buttonprimary narrowButton" v-if="selectedGroupID>0"/>
                <confirm :showButton="isTrue" body="Do you want to remove the selected group?" title="Remove" @yes="removeGroup()" v-if="selectedGroupID>0" buttonclass="button buttonprimary narrowButton"/>
            </div>
          </div>
          <div class=" vertFlex " >
            <label for="" class="titlelabel">Available Products at {{selectedGroupLocationName}}</label>
            <formentry label="Filter By" placeholder="filter products"  v-model="filterby"/>
            <label for="" class="pleasewait" v-if="pleaseWait">Please wait...</label>
            <label for="" class="responsemessage" v-if="responseMessage!=''">{{responseMessage}}</label>
            <div class="scrolldiv">
            <div v-for="result in filteredproductList" :key="result.id" :value="result.id" >
                <list-item :topLeft="getProductDisplayName(result)"  bottomLeft=""    @click="selectProduct(result)" @dblclick="addProduct()"
                    :selected="result.id==selectedProductID" :inactive="!result.isActive">
                </list-item>  
            </div>
            </div>
          </div>
          <smallbutton toplabel="Add to Group >" @click="addProduct()"  :disabled="productList.length==0" buttonclass="button buttonprimary addbutton"/>
          <div class=" vertFlex  "  v-if="groupProductList.length>0">
            <label for="" class="titlelabel">{{selectedGroupName}} Products</label>
            <div class="scrolldiv">
            <div class="horizFlexWrap ">
                <div v-for="result in groupProductList" :key="result.id" :value="result.id" >
                    <list-item :topLeft="getProductDisplayName(result)"  bottomLeft=""    @click="selectGroupProduct(result)" @dblclick="removeProduct()"
                        :selected="result.id==selectedGroupProductID" :inactive="!result.isActive">
                    </list-item>  
                </div>
            </div>
            </div>
            <div>
                <div class="horizFlex">
                    <smallbutton toplabel="Up" @click="moveUp()" class="button primarybutton narrowButton"></smallbutton>
                    <smallbutton toplabel="Down" @click="moveDown()" class="button primarybutton narrowButton"></smallbutton>
                    <smallbutton toplabel="Remove" @click="removeProduct()" ></smallbutton>
                    
                </div>
            </div>
            
            
          </div>
      </div>
    
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import listItem from './group.vue'
import smallbutton from '../controls/button.vue'
import addgroup from '../actions/addgroup.vue'
import renamegroup from '../actions/renamegroup.vue'
import confirm from '../actions/confirm.vue'
import datamethods from '../../services/datamethods.js'
import formentry from '../controls/entry.vue'
export default {
    name:'productgroup',
    components:{
        listItem,
        smallbutton,
        addgroup,
        confirm,
        renamegroup,
        formentry
      
 
    },
    mixins:[datamethods],
    computed:{
         ...mapFields([
            'pleaseWait',
            'responseMessage',
            'selectedGroupID',
           'selectedGroupName',
           'selectedGroup',

           'selectedGroupLocationId',
           'selectedGroupLocationName',
           'selectedProductID',
           'selectedProductName',
           'selectedProductPrice',
           'selectedGroupProductID',
           'selectedGroupProductName',
           'groupList',
           'productList',
           'groupProductList',
         ]),
         filteredproductList(){
            if(this.filterby==null){
                return this.productList
            }else{
                var filtered =[]
                let filterbystring = this.filterby.toLowerCase()
                console.log(filterbystring)
                this.productList.forEach(myFunction2)
            
                function myFunction2(item, index, arr) {
                    let p=arr[index]
                    if(p.name.toLowerCase().includes(filterbystring) ){
                        filtered.push(p)
                    }
                } 
                return filtered
            }
         }

    },
    mounted(){
        this.getGroups();
        //this.getProducts();
    },
    data(){
        return{
           filterby:null,
           isTrue:true
        }
    },
    methods:{
        filterproducts(){
            console.log('filter me')
        },
        getProductDisplayName(result){
            var price =''
            if (result.price) {
                price= "$" + Number(result.price).toFixed(2);
            }
      
            return result.name + ' ' + price
        },
        displayName(result){
            return result.name + " - " + result.locationName 
        },
        async addProduct(){
            //if already in group... msg
            if(this.isInGroup(this.selectedProductID)){
                this.responseMessage = this.selectedProductName + " is already in the group"
                return
            }
            //determine highest ordinal
            var ord = 0
            if(this.groupProductList.length>0){
                ord=this.groupProductList[this.groupProductList.length-1].ordinal + 1
            }
            console.log('addp ' + ord)
            let req = {
                productId:this.selectedProductID,
                productGroupId:this.selectedGroupID,
                isActive:true,
                ordinal:ord
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttogroup')
            //handle response
            if(response.Successful){
                 var p={
                    id:this.selectedProductID,
                    name:this.selectedProductName,
                    isActive:true,
                    ordinal:ord,
                    price:this.selectedProductPrice
                }
                this.groupProductList.push(p)

                //var index = this.productList.findIndex(x=>x.id===this.selectedProductID)
                //this.productList.splice(index,1)
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
            

        },
        isInGroup(id){
            var inGrp = false
            console.log('checkgrp ' + id)
            this.groupProductList.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let p=arr[index]
                if(p.id==id){
                    console.log('ingrp')
                    inGrp= true
                }
            } 
            return inGrp //didn't find it
        },
        async removeProduct(){
            let req = {
                productId:this.selectedGroupProductID,
                productGroupId:this.selectedGroupID,
                isActive:false,
                ordinal:this.groupProductList.length
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttogroup')
            //handle response
            if(response.Successful){
                 var p={
                    id:this.selectedGroupProductID,
                    name:this.selectedGroupProductName,
                    isActive:true
                }
                //this.productList.push(p)

                var index = this.groupProductList.findIndex(x=>x.id===this.selectedGroupProductID)
                console.log(index)
                this.groupProductList.splice(index,1)
                    
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
            
        },
        async removeGroup(){
            let req = {
                productGroup:{
                    id:this.selectedGroupID
                }
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'removeproductgroup')
            //handle response
            if(response.Successful){
                this.getGroups();
               
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        moveUp(){
            
            var index = this.groupProductList.findIndex(x=>x.id===this.selectedGroupProductID)
            console.log('up ' + index)
            if(index==0){return}
            this.swap(this.groupProductList,index-1,index)
            
        },
        async swap(array,index1, index2){
            //p1 update ordinal
            let req = {
                productId:array[index1].id,
                productGroupId:this.selectedGroupID,
                isActive:true,
                ordinal:array[index2].ordinal
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttogroup')
            //handle response
            if(response.Successful){
                //update the second ordinal
                 let req2 = {
                    productId:array[index2].id,
                    productGroupId:this.selectedGroupID,
                    isActive:true,
                    ordinal:array[index1].ordinal
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req2,'producttogroup')  
                if(response.Successful){
                    //swap local 
                    console.log('swap ' + index1 + ' ' + index2)
                    //first the ordinals
                   var tmpord = array[index1].ordinal
                   array[index1].ordinal = array[index2].ordinal
                   array[index2].ordinal = tmpord

                   //then the actual array items
                   var tmp = array[index1]
                    array[index1]=array[index2]
                    array[index2]=tmp  
                    
                }
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;



            
        },
        moveDown(){
            
            var index = this.groupProductList.findIndex(x=>x.id===this.selectedGroupProductID)
            console.log('dwn ' + index)
            if(index==this.groupProductList.length-1){return}
            this.swap(this.groupProductList,index+1,index)
        },
        async selectGroup(result){
            this.selectedGroupID=result.id
            this.selectedGroup=result
            this.selectedGroupName=result.name
            this.selectedGroupLocationId=result.locationId
            this.selectedGroupLocationName  = result.locationName
            //get group data
            let req = {
                locationId:this.selectedGroupLocationId,
                productGroupId:result.id
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'products_bygroup')
            //handle response
            if(response.Successful){
                 this.groupProductList=response.productList
                this.getProducts(); //get products related to this location
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          
        },
        selectGroupProduct(result){
            this.selectedGroupProductID=result.id
            this.selectedGroupProductName=result.name
        },
        selectProduct(result){
            this.selectedProductID=result.id
            this.selectedProductName=result.name
            this.selectedProductPrice=result.price
        },
        async getGroups(){
            let req = {
                locationId:-1
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productgroup')
            //handle response
            if(response.Successful){
                 this.groupList=response.ProductGroupList
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getProducts(){
            let req = {
                locationId:this.selectedGroupLocationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productbylocation')
            //handle response
            if(response.Successful){
                 this.productList=response.productList
                this.productList.sort(compare)

                function compare(a,b){
                    if(a.name< b.name){
                        return -1;
                    }
                    if(a.name > b.name){
                        return 1;
                    }
                    return 0;
                }

            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        
    }
}
</script>

<style>
.outer{
  height:70vh;
     margin:0px;
    display:grid;
    grid-template-columns:2fr 2fr 1fr 4fr;
    grid-template-rows:auto;

}
.narrowButton{
    width:40px;
}
.addbutton{
    width:150px;
    margin-top:33px;
}
.scrolldiv{
    overflow-x:hidden;
    overflow-y:auto;
    height:60vh;
}
</style>


/* this.groupList=[
                {
                    id:1,
                    name:'Group 1',
                    locationName:'loc1',
                    isActive:true,
   
                },
                {
                    id:2,
                    name:'Group 2',
                    locationName:'loc1',
                    isActive:true,
                    
                },
                {
                    id:3,
                    name:'Group 3',
                    locationName:'loc2',
                    isActive:true,
                  
                },
            ] */

            this.groupProductList=[
                {
                    id:1,
                    name:'Product Nice 1',
                    isActive:true
                },
                {
                    id:2,
                    name:'Product Long Name 2',
                    isActive:true
                },
                {
                    id:3,
                    name:'Product 3',
                    isActive:true
                }
            ]
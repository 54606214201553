<template>
  <header>
        <div class="logoArea">
            <img alt="school logo" :src="schoolLogoFilePath"  class="logo" v-if="showHeader">
            <!-- <p class="schoolname" v-show="showHeader">{{schoolname}}</p> -->
        </div>

        <div class="navArea" v-if="loggedIn">
            <button :class="navButtonClass('openclose')" @click="selectNav('openclose')" v-if="showOpenClose" :disabled="disableTab('Open Close Tab')">
                Open/Close
            </button>
            <button :class="navButtonClass('productgroup')" @click="checkNav('productgroup')" :disabled="disableTab('Product Group Tab')">
                Product Groups 
            </button>
            <button :class="navButtonClass('product')" @click="checkNav('product')" :disabled="disableTab('Product Tab')">
                Products 
            </button>
            <button :class="navButtonClass('transactions')" @click="checkNav('transactions')" :disabled="disableTab('Transactions Tab')">
                Transactions 
            </button>
            <!-- <button :class="navButtonClass('wallet')" @click="selectNav('wallet')">Wallets</button> -->
            <!-- <button :class="navButtonClass('explorer')" @click="selectNav('explorer')">Transactions</button>
            
            <button :class="navButtonClass('report')" @click="selectNav('report')">Reports</button>
            <button :class="navButtonClass('admin')" @click="selectNav('admin')">Admin</button> -->
        </div>  
        
        <div class="userArea vertFlex rightjustified" v-if="loggedIn && showHeader">
            <p class="userlabel bold">{{loggedInUser.name}}</p>
            <p class="userlabel" v-if="loggedInUser.isAdmin">ADMIN</p>       
            <linkbutton @click="logout()" toplabel="Logout" />
            <!-- <updatepassword :isAdminChangePassword="false"   :username="username" /> --> 
        </div>
        <securityoverride ref="override"  @overridesuccessful="overrideSuccessful"/>
    </header>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import linkbutton from './controls/linkbutton.vue'
import securityhelper from '../services/securityhelper.js'
import securityoverride from './actions/securityoverride.vue'
// import updatepassword from './actions/updatepassword.vue'
import datamethods from '../services/datamethods'

export default {
    name: "navheader",
    components:{
        linkbutton,
       securityoverride
    },
    mixins:[datamethods,securityhelper],
    data() {
        return {
            // schoolLogoFilePath:'./../' + window.SCHOOL_LOGO,
            schoolname:window.SCHOOL_NAME,
            savedCurrentNav:null
        };
    },
    computed:{
        ...mapFields([
            'currentNav',
            'loggedIn',
            'loggedInUser',
            'lastRefreshDateTime',
            'username',
            'oldpassword',
            'logoOverride',
            'imageBasePath',
            'showHeader'
        ]),
        schoolLogoFilePath(){
            
                return './../' + window.SCHOOL_LOGO
            

        },
        showOpenClose(){
            if(window.SHOW_OPEN_CLOSE==undefined){return true}
            else{return window.SHOW_OPEN_CLOSE}
        },
        useAppSecurity(){
            if(window.USE_APP_SECURITY==undefined){return false}
            else{return window.USE_APP_SECURITY}
        }
    },
    methods:{
        //for new app security
        disableTab(name){
            return this.useAppSecurity && !this.canAccess(name)
        },
        checkNav(name){
            //check for app security
            if (!this.useAppSecurity){this.selectNav(name); return;}

            this.savedCurrentNav=name //stash this so we can go to it after it works
            if(this.canAccess_allowOverride('POSAdmin','Product Group Tab')){
                //console.log('already has access')
                this.selectNav(name)
            }
            
        },
        selectNav(name){

            this.currentNav=name;
        },
        overrideSuccessful(){
            //console.log('override!!! call success function')
            this.selectNav(this.savedCurrentNav)
        },
        navButtonClass(name){
            if(this.currentNav==name){
                return "selectedNav"
            }else{
                return "unselectedNav"
            }
        },
        logout(){
            this.loggedIn=false;
        },
        /* async getImageBasePath(){
            //call api
            var response = await this.callAPI_Image(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        }, */
    },
    mounted(){
        //this.getImageBasePath();
    }
}
</script>

<style>
header{
    grid-area:header;
    padding:10px 20px 10px 10px;
    display:grid;
    grid-template-columns:2fr 4fr 1fr;
    grid-template-rows:auto;
    grid-template-areas: 
        "logo nav user";
    font-size:medium;
    background-color:var(--header-color);
}
.logo{
    max-width: 100%;
    max-height:75px;
}
.navArea{
    padding:0px;
    margin:0px;
}
.selectedNav{
    color:var(--light-text-color);
    background-color:var(--button-color);
    border: 4px solid var(--highlight-button-color);
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
     margin:10px;

}
.unselectedNav{
    color:var(--light-text-color);
    background-color:var(--button-color);
    border: none;
    border-radius:2px;
    width:130px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
}
.schoolname{
    font-size:large;
    text-align: center;
    margin:0px 0px 0px 20px;
}
.userlabel{
   
    margin:0px 0px 10px 0px;
    
}
.userArea{
  grid-area:user;
}
.modeImage{
    height:25px;
    margin:5px;
}
.buttoninside{
  display:flex;
  flex-direction: column;
  margin:0px;
    padding:0px;
}
.buttonlabel{
    margin:0px;
}
</style>
<template>
<div>
    <smallbutton toplabel="Add Group" @click="startAdd()" v-if="showButton" :buttonclass="buttonclass"/>
    <linkbutton toplabel="Add Group" @click="startAdd()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">Add Product Group</label>
                <br/>
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Location</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="productGroup.locationId" required>
                        <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                            {{loc.name}}
                        </option>
                    </select>
                </div>
                <formentry   label="Name"  v-model="productGroup.name" :isrequired="trueParam" />
                
                <div>
                    <label for="" class="required">{{requiredFieldLabel}}</label>
                </div>

                
                <smallbutton  @click="save()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../services/datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'addgroup',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
        
        showButton:{
            type:Boolean,
            default:false
        },
        buttonclass:{
          type:String,
          default:"button buttonprimary"
        },
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            productGroup:{
                name:'',
                locationId:0,
                isActive:true,
                id:-1
            },
            locationList:[],
            requiredFieldLabel:'* required',
            trueParam:true
        }
    },
    computed:{
        ...mapFields([


        ]),
        
    },
    mounted(){
        this.GetLocations()
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        
      
        startAdd(){
           
            this.productGroup={
                name:'',
                locationId:0,
                isActive:true,
                id:-1
            }   

            this.$refs.mymodal.openModal()
        },
        async save(){
           let req = {
                productGroup:this.productGroup
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'addproductgroup')
            //handle response
            if(response.Successful){
                this.$emit('saved')
                this.$refs.mymodal.closeModal()
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },

       async GetLocations(){
            
                this.beforeAPI();
                var response = await this.callAPI(null,'locations')

                if(response.Successful){
                    this.locationList=response.locationList
                
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
           
        },
        
       
        
    }
}
</script>

<style>

</style>






























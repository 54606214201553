<template>
    <div>
        <smallbutton @click="UpdateProduct()" :toplabel="updateText" :class="buttonclass"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="myform">
                    <label class="titlelabel">{{updateText}}</label>
                    
                    <formentry  inputtype="text"  label="Name" :isrequired="isRequired('Name')"  v-model="Product.name" v-if="isVisible('Name')"/>
                    <div class="entry"  v-if="isVisible('IsActive')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Is Active</label>
                        </div>
                        <div class="entryTextDiv">
                            <input class="entryCheckbox" type="checkbox" v-model="Product.isActive" >
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('Type')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Type</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect'  v-model="Product.typeId" :required="isRequired('Type')" >
                            <option v-for="t in TypeList" :key="t.id" :value="t.id" >
                                {{t.name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('Line')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Line</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="Product.lineId" :required="isRequired('Line')" >
                            <option v-for="l in LineList" :key="l.id" :value="l.id" >
                                {{l.name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('Vendor')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Vendor</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="Product.vendorId" :required="isRequired('Vendor')" >
                            <option v-for="v in VendorList" :key="v.id" :value="v.id" >
                                {{v.name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <br>
                    <formentry  inputtype="text"  label="Cost"  v-model="Product.cost"  v-if="isVisible('Cost')" :isrequired="isRequired('Cost')"/>
                    <formentry  inputtype="text"  label="Price"  v-model="Product.price"  v-if="isVisible('Price')" :isrequired="isRequired('Price')"/>
                    <div class="entry"  v-if="isVisible('IsRetail')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Retail</label>
                        </div>
                        <div class="entryTextDiv">
                            <input class="entryCheckbox" type="checkbox" v-model="Product.isRetail" >
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('IsTaxable')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Taxable</label>
                        </div>
                        <div class="entryTextDiv">
                            <input class="entryCheckbox" type="checkbox" v-model="Product.isTaxable" >
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('IsFinancialAid')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Financial Aid Eligible</label>
                        </div>
                        <div class="entryTextDiv">
                            <input class="entryCheckbox" type="checkbox" v-model="Product.isFinancialAidEligible" >
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('IsFinancialAid') && Product.isFinancialAidEligible">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">{{FACodeText}} - FA</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="glCode_Anthology_FA" :required="isRequired('FinancialAidCode')" >
                            <option v-for="l in FACodeList_FA" :key="l.Name" :value="l.Name" >
                                {{l.Name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="entry"  v-if="isVisible('IsFinancialAid')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">{{FACodeText}} - Cash</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelect' v-model="glCode_Anthology_Cash" :required="isRequired('FinancialAidCode')" >
                            <option v-for="l in FACodeList_Cash" :key="l.Name" :value="l.Name" >
                                {{l.Name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <br>

                    <formentry   label="Code"  v-model="Product.code"  v-if="isVisible('Code')" :isrequired="isRequired('Code')"/>
                    <formentry   label="UPC" v-model="Product.upc"  v-if="isVisible('UPC')" :isrequired="isRequired('UPC')"/>

                    <formentry   label="On Hand" v-model="Product.onHandQuantity"  v-if="isVisible('OnHand')" :isrequired="isRequired('OnHand')"/>
                    <br>
                    <div>
                        <label class="  right10">Locations:</label>
                        <div v-for="pl in Product.productLocationList" :key="pl.id" :value="pl.id" >
                            <div class="locationdiv">
                                
                                <input class="locationCheckbox" type="checkbox" v-model="pl.isActive" @input="pl.isDirty=true;" >
                                
                                
                                <p >{{pl.locationName}}</p>
                            </div>
                            
                        </div>
                    </div>

    
                    <smallbutton  @click="saveNewProduct()" toplabel="Save" />
                    <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    <div>
                        <label class="responsemessage">{{message}}</label>
                   </div>
              </form>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from './actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilbutton from '../controls/pencilbutton.vue'
    export default {
        name:'updateProduct',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton
        },
        props:{
            inProduct:{
                type:Object,
                required:true
            },
            ProductTypeText:{
                type:String,
                default:'Product'
            },
            isAdd:{
                type:Boolean,
                default:false
            },
            buttonclass:{
                type:String,
                default:'updatebutton'
            },
            locationId:{
                type:Number,
                default:0
            },
            
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                message:'',
                Product:{},
                TypeList:[],
                LineList:[],
                VendorList:[],
                ProductList:[],
                FACodeList:[],

                glCode_Anthology_FA:null,
                glCode_Anthology_Cash:null
                
            }
        },
        computed:{
            ...mapFields([
               'locationList',
            ]),
            FACodeList_FA(){
            
                let temp = this.FACodeList;
                if(temp!=undefined){
                    temp = temp.filter((item)=>{
                        return (item.isFinancialAid)
                    })
                }
                
                return temp
                
            },
            FACodeList_Cash(){
            
                let temp = this.FACodeList;
                if(temp!=undefined){
                    temp = temp.filter((item)=>{
                        return (item.isCash)
                    })
                }
                
                return temp
                
            },
            updateText(){
                if(this.isAdd){
                    return "Add " + this.ProductTypeText
                }else{
                    return "Update " + this.ProductTypeText
                }
                
            },
            showFA(){
                if(window.SHOW_FINANCIALAID==undefined){return false}
                else{return window.SHOW_FINANCIALAID}
            },
            FACodeText(){
                if(window.FINANCIAL_AID_CODE_TEXT==undefined){return 'Financial Aid Code'}
                else{return window.FINANCIAL_AID_CODE_TEXT}
                
            }
        },
        methods:{
            toCurrency(value){
                return "$" + Number(value).toFixed(2);
            },
            isRequired(field){
                
                switch (field) {
                    case "Name":
                        return true
                        break;
                    case "Type":
                        return window.REQUIRE_TYPE
                        break;
                    case "Line":
                        return window.REQUIRE_LINE
                        break;
                    case "Code":
                        return window.REQUIRE_CODE
                        break;
                    case "Vendor":
                        return window.REQUIRE_VENDOR
                        break;
                    case "Cost":
                        return window.REQUIRE_COST
                        break;
                    case "Price":
                        return window.REQUIRE_PRICE
                        break;
                    case "FinancialAidCode":
                        return window.REQUIRE_FINANCIALAIDCODE
                        break;
                    case "UPC":
                        return window.REQUIRE_UPC
                        break;
                    case "OnHand":
                        return window.REQUIRE_ONHAND
                        break;

                    default:
                        return false
                        break;
                }
            },
            isVisible(field){
                
                console.log('isvis ' + field)
                switch (field) {
                    case "IsFinancialAid":
                        return this.showFA
                        break;
                    default:
                        return true
                        break;
                }
            },
            toCurrency(value){
                return  Number(value).toFixed(2);
            },
           UpdateProduct(){
            this.message=null
            if(this.isAdd){
                this.Product = {
                    id:0,
                    name:null,
                    lineId:0,
                    typeId:0,
                    vendorId:0,
                    cost:0.00,
                    price:0.00,
                    code:null,
                    upc:null,
                    isTaxable:false,
                    isRetail:true
              
                }
                this.addLocations();
            }else{
                this.Product = this.inProduct
                this.Product.cost = this.toCurrency(this.Product.cost)
                this.Product.price = this.toCurrency(this.Product.price)
                if(this.Product.glCode_Anthology!=null){
                    
                    var splitcode=this.Product.glCode_Anthology.split("|")
                    if(splitcode.length==2){
                        this.glCode_Anthology_FA =splitcode[0]
                        this.glCode_Anthology_Cash=splitcode[1]
                    }
                    else{
                        this.glCode_Anthology_FA =null
                        this.glCode_Anthology_Cash=splitcode[0]
                    }
                    
                }
                
            }
            
            this.getTypes()
            this.getLines()
            this.getVendors()
            if(this.showFA){this.getFACodes()}
            this.$refs.mymodal.openModal()
           },
            addLocations(){
                var locList=[]
                var locId = this.locationId
                this.locationList.forEach(myFunction2)
            
                function myFunction2(item, index, arr) {
                    let p=arr[index]
                    var pl={
                        locationId:p.id,
                        locationName:p.name,
                        productId:-1,
                        isActive:false
                    }
                    if(p.id==locId){pl.isActive=true}
                    locList.push(pl)
                } 
                this.Product.productLocationList=locList
            },
            checkClass(selected){
                if(selected){
                    return "entryCheckLabelbold"
                }else{
                    return "entryCheckLabel"
                }
            },
            cancelupdate(){
                this.$refs.mymodal.closeModal()
                this.$emit('refresh')
                
            },
            async saveNewProduct(){
                if(this.$refs.myform.checkValidity()==false){return}
                
                if(this.isRequired("Type")){
                    if(this.Product.typeId==-1){return}
                }
                if(this.isRequired("Line")){
                    if(this.Product.lineId==-1){return}
                }
                this.Product.locationId=this.locationId
                this.Product.glCode_Anthology = this.glCode_Anthology_FA + "|" + this.glCode_Anthology_Cash
                let req = {
                    Product:this.Product,

              
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'updateProduct')
                if(response.Successful){
                    //save prod sale
    
    
                    this.$refs.mymodal.closeModal()
                    console.log(response)
    
                    if(this.isAdd){
                        this.$emit('refreshnew',response.Product.ID, response.walletId)
                    }else{
                        this.$emit('refresh')
                    }
                    
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            
             
           
            async getTypes(){
                let req = {
                    locationId:this.Product.locationId_Primary
                }
                this.$store.dispatch('beforeAPI');;
                var response = await this.callAPI(req,'producttype')
    
                //handle response
                if(response.Successful){
                this.TypeList = response.productTypeList
        
                if(this.TypeList.length==1){
                    
                    
                }
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            async getLines(){
                let req = {
                    locationId:this.Product.locationId_Primary
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'productline')
    
                //handle response
                if(response.Successful){
                this.LineList = response.ProductLineList
        
                if(this.LineList.length==1){

                
                  
                    
                }
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            async getVendors(){
                let req = {
                    locationId:this.Product.locationId_Primary
                }
                this.$store.dispatch('beforeAPI');;
                var response = await this.callAPI(req,'vendor')
    
                //handle response
                if(response.Successful){
                this.VendorList = response.vendorList
        
                if(this.ProductTypeList.length==1){
          
                }
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            async getFACodes(){
                
                this.$store.dispatch('beforeAPI');;
                var response = await this.callAPI(null,'financialaidcodes')
    
                //handle response
                if(response.Successful){
                this.FACodeList = response.FinancialAidCodeList
        
                
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            
        }
    }
    </script>
    
    <style>
    .entryCheckLabel{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
    }
    .entryCheckLabelbold{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
        font-weight:bold;
    }
    .updatebutton{
        height:30px;
        width:150px;
        border-radius: 5px;
        padding:3px;
        margin:0px 0px 10px 0px;
    }
    .locationcheck{
    height:15px;
    margin-top:15px;
    margin-right:5px;
}
.locationdiv{
    text-align: left;
    display:grid;
    grid-template-columns: 1fr 15fr;
}
.locationCheckbox{
    height:15px;
    margin-top:15px;
}
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
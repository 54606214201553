<template>
<div>
    <smallbutton :toplabel="title" @click="Show()" v-if="showButton" :disabled="disabled" :buttonclass="buttonclass"/>
    <linkbutton :toplabel="title" @click="Show()" v-if="showLink" :disabled="disabled"/>
    <!-- <removebutton @click="Show()" v-if="showImageButton" :disabled="disabled"/> -->

    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <label class="titlelabel">{{title}}</label>
            <label :class="bodyclass" >{{body}}</label>
            <div>
                <smallbutton  @click="CloseWithYes" toplabel="Yes"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="No"/>
            </div>
            <div>
              <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'

export default {
    name:'confirm',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        
    },
    props:{
        title:{
            type:String,
            required:true
        },
        body:{
            type:String,
        },
        showButton:{
            type:Boolean,
            default:false
        },
        showImageButton:{
            type:Boolean,
            default:false,
        },
        showLink:{
            type:Boolean,
            default:false
        },
        disabled:{
          type:Boolean,
          default:false
        },
        buttonclass:{
            type:String,
            default:"button buttonprimary"
        },
        isAlert:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            responseMessage:''
        }
    },
    computed:{

        bodyclass(){
            if(this.isAlert){
                return "redlabel"
            }else{return ""}
        }
    },
    methods:{
        Show(){
            this.$refs.mymodal.openModal()
        },
        async CloseWithYes(){
            this.$emit('yes')
            this.$refs.mymodal.closeModal()
        }
    }
}
</script>

<style>
.redlabel{
    color:red;
}
</style>
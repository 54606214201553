<template>
  <div class="outerDiv" :style="configStyle">
        <navheader/>

        <div :class="mainDivClass">
            <loginuser ref="loginuser" v-if="!loggedIn"/>
            <div v-if="loggedIn">
                <openclose v-if="currentNav=='openclose'" @hide="currentNav=''"/>
                <productgroup v-if="currentNav=='productgroup'"/>
                <productadmin v-if="currentNav=='product'"/>
                <transactions v-if="currentNav=='transactions'"/>
            </div>
        </div>

        <spfooter/>  
        
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import format from '../format.js'
import datamethods from '../services/datamethods.js'
import spfooter from './footer.vue'
import navheader from './navheader.vue'
import loginuser from './loginuser.vue'
import openclose from './openclose/openclose.vue'
import productgroup from './productgroup/productgroup.vue'
import productadmin from './product/productadmin.vue'
import transactions from './transactions/transactions.vue'
export default {
    components:{
        spfooter,
        navheader,
        loginuser,
        openclose,
        productgroup,
        productadmin,
        transactions
    
    },
    mixins:[datamethods, format,style],
    computed:{
        ...mapFields([
            'loggedIn',
            'currentNav',
            'username',
            'oldpassword',
            'loggedInUser.isAdmin',
            'logoOverride',
           'showHeader'

        ]),
        mainDivClass(){
            if(this.isAdmin){return "mainDivAdmin"}
            else{return "mainDivFullWidth"}
        }
    },
    methods:{
        receiveMessage(event){
            try{
                var msg =  JSON.parse(event.data)
            }
            catch(err){
                return
            }
            
            var action = msg.action
            if(action !=undefined){
            
               // console.log("PA Receive Msg: " + action)
                switch (action) {
                    case 'refresh':
                        this.currentNav=''
                        break;
                
                    default:
                        break;
                }
                
            }
        }
    },
    mounted(){
        //to receive msgs
        window.addEventListener('message', this.receiveMessage)

        this.currentNav=''
        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
        if(tmp!=null){
            this.username = tmp;
        }

        let tmp2 = urlParams.get('temp')
        if(tmp2!=null){
            this.oldpassword = tmp2;
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.loginuser.changepassword();
            });
        }

    
        let tmp4 = urlParams.get('config')
        if(tmp4!=null){this.configOverride=tmp4 }

        

        console.log("inmount")
        window.onbeforeunload= function(event){
            event.preventDefault()
        }

        tmp = urlParams.get('header')
        if(tmp!=null){this.showHeader=tmp=='true'; }

        tmp = urlParams.get('token')
        if(tmp!=null){
            console.log('sso!')
            this.$refs.loginuser.post_login(tmp)
         }
         this.setColors();
    }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";





body{
  height:95vh; 
  height: -webkit-fill-available;
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:medium;
  color:var(--text-color);
}
.outerDiv{
  margin:0px;
  padding:0px;
    height:95vh;
    display:grid;
    grid-template-rows:90px auto 90px;
    grid-template-areas: 
        "header header header"
        "center center center"
        "footer footer footer";
}
.mainDivFullWidth{

    grid-row:2;
    grid-column-start:1;
    grid-column-end: span 3;
    background-color:var(--background-color);
    padding:20px;
}
.mainDivAdmin{
    grid-row:2;
    grid-column-start:1;
    grid-column-end: span 3;
    background-color:var(--background-color);
    padding:20px;
     
}

.bordered{
    background-color: var(--frame-color);
    border-radius:5px;
    margin:10px 0px 10px 0px;
    padding:20px;
}
.responsemessage{
    font-size:large;
    color:red;
}

.horizFlex{
  display:flex;
  flex-direction: row;
  margin:0px;
}
.vertFlex{
  display:flex;
  flex-direction: column;
  margin:0px;
  
}
/* .horizFlexStretch{
  display:flex;
  padding:0px;
  flex-direction: row;
  margin:0px 0px 0px 10px ;
  justify-content:space-between;
} */
.rightjustified{
    text-align: right;
}
.leftjustified{
    text-align: left;
}
.subtitle{
    font-size:large;
    margin:20px;
}
.subtitleBold{
  font-size:large;
  margin:20px;
  font-weight: bold;
}
button:hover{
    background-color:var(--highlight-button-color);
    cursor:pointer;
}
button:disabled{
  background-color:gray;
}
.bold{
    font-weight: bold;
}
.left5{
    margin-left:5px;
}
.left10{
    margin-left:10px;
}
.right10{
    margin-right:10px;
}
.bottom10{
    margin-bottom:10px;
}
.messagetext{
    color:black;
    font-size:medium;

}
.pleasewaitlabel{
    color:orangered;
    font-size:large;
}
.titlelabel{
 
    font-size:x-large;
}
textarea{
    font:inherit;
}
</style>